import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import loadable from "@loadable/component"

import { Button } from "react-bootstrap"

import Layout from "../components/layout"
import ReviewCard from "../components/reviewCard"

import globalStyle from "../styles/global.module.css"
import styles from "./page.module.css"

import reviews from "../markdown-content/labor-book-reviews"

const BuyButton = loadable(() => import("../components/BuyNow"))

const MommysHelper = ({ data }) => {
  const [content, setContent] = React.useState()
  React.useEffect(() => {
    if (data.allMarkdownRemark.edges.length > 0)
      setContent(data.allMarkdownRemark.edges[0].node)
  }, [data])

  if (!content) return null

  return (
    <Layout className="site-content">
      <div className={styles.page}>
        <h1 className={globalStyle.entryTitle}>{content.frontmatter.title}</h1>

        <div className={styles.twoColumn}>
          <div className={styles.leftColumn}>
            <Img
              fluid={content.frontmatter.featuredImage.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain" }}
            />

            <h1
              className={[globalStyle.entryTitle, styles.entryTitle].join(" ")}
            >
              {content.frontmatter.callToAction}
            </h1>

            <div className={styles.buttonGroup}>
              <BuyButton
                id={"6955252744398"}
                title={content.frontmatter.button1Title}
                className={[
                  globalStyle.btnPrimary,
                  globalStyle.submitButtonOrange,
                  styles.button,
                  "btn",
                  "btn-primary",
                ].join(" ")}
              />
              <BuyButton
                id={"6955255038158"}
                title={content.frontmatter.button2Title}
                className={[
                  globalStyle.btnPrimary,
                  globalStyle.submitButtonOrange,
                  styles.button,
                  "btn",
                  "btn-primary",
                ].join(" ")}
              />
            </div>
          </div>

          <div className={styles.rightColumn}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
            <h3 className={styles.reviewsTitle}>Here are some reviews:</h3>
            {reviews.map((e, idx) => {
              return (
                <ReviewCard key={idx} content={e.review} reviewer={e.name} />
              )
            })}
          </div>
        </div>

        <div className={styles.footnote}>{content.frontmatter.footnote}</div>
        <div className={styles.buttonGroup}>
          <Button
            className={[
              globalStyle.btnPrimary,
              globalStyle.submitButtonOrange,
            ].join(" ")}
            href={content.frontmatter.footnoteAction}
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {content.frontmatter.footnoteTitle}
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default MommysHelper

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { id: { eq: "labor-book" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            callToAction
            button1Action
            button1Title
            button2Action
            button2Title
            footnote
            footnoteAction
            footnoteTitle
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
