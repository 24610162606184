const reviews = [
  {
    review:
      "While there is so much valuable and insightful content out there on labor and delivery, it can often get overwhelming. This book brings it back to the basics with helpful visuals and clear, comprehensive descriptions for tired, nervous, information-overloaded moms-to-be. I especially enjoyed the multitude of birthing positions pictured! So many things are going through your mind when in labor that this book makes a perfect, quick reference point for when the time comes. A must-read for all!",
    name:
      "Kristan Caballero, Mom and Wife, Review Written While Pregnant with Second",
  },
  {
    review:
      "I read Moms on Maternity’s Labor book tonight, and it’s amazing! What a creative, fantastic, and entertaining way to share the highlights of labor and delivery in a very palatable way. Aimee captures the most important labor information in a powerful and succinct manner. The pages of this book hit on all the major points that I cover in a 3-hour class! This can be an excellent class supplement, teaser, or reminder as people prepare for labor and childbirth.  The fun graphics of this book help bring it all to life so it can be easily digested and remembered. I can’t wait to share it with my doula clients as well as our moms at Claris Health.",
    name: "Talitha Phillips, Labor and Postpartum Doula & CEO of Claris Health",
  },
];
export default reviews
