import React from "react"

import { Row, Col } from "react-bootstrap"
import styles from "./reviewCard.module.css"

const ReviewCard = ({ content, reviewer }) => {
  return (
    <Row className={styles.cardContent}>
      <Col className={styles.cardColumn}>
        <Row className={styles.reviewContent}>
          <Col>
            <p className={styles.description}><i><q>{content}</q></i></p>
          </Col>
        </Row>
        <Row className={styles.bannerFooter}>
          <Col>
            <p className={styles.footer}>{reviewer}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ReviewCard
